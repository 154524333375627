import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {AiOutlineLogout} from "react-icons/ai"

const Logout = () => {
  const navigate = useNavigate();
  const handleClick = async () => {    
    localStorage.clear();
    navigate("/login");
  }
  return (
    <Button>
        <AiOutlineLogout onClick={handleClick}/>
    </Button>
  )
}

const Button = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    border-radius: 0.5rem;
    background-color: blue;
    border: none;
    cursor: pointer;
    svg {
    font-size: 1.3rem;
    color: #ebe7ff;
    }
`;

export default Logout